<div class="containerDiv">
  <div *ngIf="IsUserAccessPermission; else AccessDenied">
    <div class="mainDiv" *ngIf="workspaceModel.workspaceId != null; else InProgressReviewScreen">
      <app-loading-screen></app-loading-screen>
      <div class="d-flex">
        <div class="d-none d-md-block d-lg-none">
          <app-shortcut></app-shortcut>

        </div>
        <div class="d-none d-md-block d-lg-none">
          <div class="btn-group">
            <a id="customDropdown" class="btn btn-light btn-sm customDropdown dropdown-toggle" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="assets/images/EDP/Engagement/more_horiz-24px.svg" alt="">
            </a>
            <div class="dropdown-menu" aria-labelledby="customDropdown">
              <span class="dropdown-menu-arrow"></span>
              <a class="dropdown-item" (click)="SetWorkspaceIdForEngageSummaryReport(true)">Create report</a>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb" class="pt-3" style="height: 35px;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item disabled">
            <label>
              Basic info
              <span>
                <img style="vertical-align: top;"
                  src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg" alt="Basic info" />
              </span>
            </label>
          </li>
          <li class="breadcrumb-item disabled">
            <label>
              Questionnaire
              <span>
                <img style="vertical-align: top;"
                  src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg"
                  alt="Questionnaire" />
              </span>
            </label>
          </li>
          <li class="breadcrumb-item active">
            Review
          </li>
        </ol>
      </nav>
      <div>
        <div class="row pt-1 mx-0">
          <div class="col-md-11 col-sm-7 col-xs-10 col-lg-8 pl-0">
            <div class="row">
              <div *ngIf="workspaceType=='E'">
                <div class="col-12 workspaceMetadata my-2 mb-0 pl-3">
                  <a target="_blank" class="workspaceMetadata"
                    routerLink="/ClientDetails/{{workspaceModel.clientId}}">{{workspaceModel.clientName}}</a>
                </div>
                <div class="col-12 my-1">
                  <!-- Review -->
                  <div class="col-12 my-0 pl-0"
                    [ngClass]="{true: 'top-space-sm', false: 'top-space-lg'}[solutionCodes.length > 4]">
                    <span class="workspaceHeading">{{workspaceModel.engagementName}}</span>
                    <span class="workspaceHeading"
                      *ngIf="currentEngagement.reacceptanceEngagementId != null && currentEngagement.reacceptanceEngagementId > 0">
                      (Continuance)</span>
                    <span class="inline-block px-2 workspaceHeading">|</span>
                    <span class="workspaceHeading">{{workspaceModel.solutionCode}}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="workspaceType=='C'">
                <div class="col-12 workspaceMetadata pl-3 mt-1">
                  <a target="_blank" class="workspaceMetadata mr-1"
                    routerLink="/ClientDetails/{{workspaceModel.clientId}}">{{workspaceModel.clientName}}</a>
                  <span class="inline-block">|</span>
                  <a target="_blank" class="workspaceMetadata ml-1"
                    routerLink="/EngagementDetail/{{currentEngagement.engagementId }}">{{workspaceModel.engagementName}}</a>
                  <span class="workspaceMetadata"
                    *ngIf="currentEngagement.reacceptanceEngagementId != null && currentEngagement.reacceptanceEngagementId > 0">
                    (Continuance)</span>
                </div>
                <div class="col-12">
                  <!-- Review -->
                  <div class="col-12 my-0 pl-0 mb-1"
                    [ngClass]="{true: 'top-space-sm', false: 'top-space-lg'}[solutionCodes.length > 4]">
                    <span class="workspaceHeading">{{this.engagementChangeOrders.changeOrders[0].changeOrderName}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 workspaceWorkflowStatus">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item" [class.active]="workspaceModel.status == TeamReviewStatus">
                    <!-- add/remove active class based on the workspace status-->
                    <label href="">
                      Team review
                      <img *ngIf="workspaceModel.status == TeamReviewStatus" class="align-top mx-2"
                        src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-purple.svg"
                        alt="Team review" />
                      <img *ngIf="workspaceModel.status != TeamReviewStatus" class="align-top mx-2"
                        src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg"
                        alt="Team review" />
                    </label>

                  </li>
                  <li class="breadcrumb-item" [class.active]="workspaceModel.status == EPPMDReviewStatus">
                    <label href="">
                      EPPMD review

                      <img *ngIf="workspaceModel.status == EPPMDReviewStatus" class="align-top mx-2"
                        src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-purple.svg"
                        alt="EPPMD review" />
                      <img *ngIf="workspaceModel.status != EPPMDReviewStatus" class="align-top mx-2"
                        src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg"
                        alt="EPPMD review" />
                    </label>
                  </li>
                  <li class="breadcrumb-item" [class.active]="workspaceModel.status == RPInReviewStatus">
                    Reviewing Professional approval
                  </li>
                </ol>
              </div>
              <div class="col-12 workspaceLastActivity">
                Last activity by <span class="lastModifiedBy cursor-pointer"
                  (mousedown)="DisplayContactPopup()">{{workspaceModel.lastActivityBy}} </span> on
                <span>{{workspaceModel.lastActivityDate | date: "MM/dd/yyyy"}} at
                  {{workspaceModel.lastActivityDate | date: "hh:mm a UTC"}}</span>
              </div>

            </div>
          </div>
          <div class="col-md-1 col-sm-5 col-xs-2 col-lg-4 d-none d-lg-block px-0">
            <div class="row m-0 mt-1">
              <div class="col-9 col-md-9 px-0 float-left">
                <app-shortcut></app-shortcut>
              </div>
              <div class="col-3 col-md-3 pl-2 float-right">
                <div class="btn-group">
                  <a (click)="DisplayReviewNotes()" data-toggle="tooltip" data-placement="bottom" title="Review notes"
                    id="review-NotesIcon" class="position-relative btn-light btn-sm customDropdown" role="button"
                    aria-haspopup="true" aria-expanded="false">
                    <img src="../../../assets/images/EDP/Workspace/library_add_check-18px-blue.svg" alt="" class="p-1">
                    <span *ngIf="workspaceOpenReviewPointsCount > 0" class="redicon"></span>
                  </a>
                  <span class="mt-1 ml-2 font-weight-bold"
                    *ngIf="workspaceOpenReviewPointsCount > 0">{{workspaceOpenReviewPointsCount}}</span>
                  <a id="customDropdown" class=" btn-light btn-sm customDropdown dropdown-toggle ml-2" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="assets/images/EDP/Engagement/more_horiz-24px.svg" alt="">
                  </a>
                  <div class="dropdown-menu" [ngClass]="ActionPopoverPositioningCSSClass"
                    aria-labelledby="customDropdown">
                    <span class="dropdown-menu-arrow" [ngClass]="ActionPopoverArrowPositioningCSSClass"></span>
                    <a class="dropdown-item" (click)="SetWorkspaceIdForEngageSummaryReport(true)">Create report</a>
                    <div
                      *ngIf="((currentEngagement.status=='Team review'||currentEngagement.status=='EPPMD review'||currentEngagement.status=='RP InReview'||currentEngagement.status=='RP rule engine error'||currentEngagement.status=='InReview') && (isIndusFlagEnabled))">
                      <a class="dropdown-item"
                        *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted') && (!isIndusFlag)"
                        (click)="INDUSmodelDailog('Record INDUS consent')">Record INDUS consent</a>
                      <a class="dropdown-item"
                        *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted') && (isIndusFlag)"
                        (click)="INDUSmodelDailog('Remove INDUS consent')">Remove INDUS consent</a>

                    </div> <a class="dropdown-item"
                      *ngIf="workspaceModel.isAllowMultipleTemplates && !isAddDocumentLimitExceeded 
                      && !currentEngagement.appliedTnC && workspaceModel.status != 'Declined' && workspaceModel.status != 'Abandoned'
                      && currentEngagement.status != 'RP rule engine error' && !isPureNotificationOnlyProfessional && !isPureRP"
                      (click)="DisplayAddWorkspaceDocument()">Select additional document template</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 my-3">
        <div #clmGoverningAgreementStatusReviewDiv></div>
      </div>
      <div class="row mx-0 my-3">
        <div *ngIf="isClientConsentWarningEnabled" class="alert-warning col-md-12">
          <span class="msg-icon">
            <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Inprogress session">
          </span>
          <span class="d-inline-block align-middle msg-text">
            This client has not yet consented to the assignment of the governing agreement for Grant Thornton Advisors
            LLC. Please contact the PPMD and <a href="mailto:ClientAssignment@us.gt.com">Clientassignment@us.gt.com</a>
            to discuss next steps.
          </span>
        </div>
        <div
          *ngIf="!idLoggedInUserHasWorkspaceAccess && !isLoggedInUserSystemAdmin && !isQualityUserLoggedIn && workspaceModel.status == 'Declined' && workspaceModel.status == 'Abandoned'"
          class="alert-danger col-md-12">
          <span class="msg-icon">
            <img src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="Access Error">
          </span>
          <span class="d-inline-block align-middle msg-text">
            You do not have access to this review screen as you are not a member of the engagement
            {{workspaceModel.engagementName}}
          </span>
        </div>
        <div *ngIf="workspaceModel.status == 'Approved'" class="alert-success col-md-12">
          <span class="d-inline-block align-middle msg-text">
            This engagement is approved and the document has been posted to the centralized repository for further
            processing (if necessary).
          </span>
        </div>
        <div *ngIf="workspaceModel.status == 'Abandoned'" class="alert-danger col-md-12">
          <span class="msg-icon">
            <img src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="Engagement Abandoned">
          </span>
          <span class="d-inline-block align-middle msg-text">
            This engagement abandoned on {{workspaceModel.abandonedDeclinedDate| date:'MM/dd/yyyy'}}.
          </span>
        </div>
        <div *ngIf="workspaceModel.status == 'Declined'" class="alert-danger col-md-12">
          <span class="msg-icon">
            <img src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="Engagement declined">
          </span>
          <span class="d-inline-block align-middle msg-text">
            This engagement declined on {{workspaceModel.abandonedDeclinedDate| date:'MM/dd/yyyy'}}.
          </span>
        </div>
        <div *ngIf="isNewVersionForCR_CMSEP_AA" class="alert-danger col-md-12">
          <span class="msg-icon">
            <img src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="version">
          </span>
          <span class="d-inline-block align-middle msg-text">
            A new version has been released for the questionnaire being used, and the engagement must transition to this
            new version. Responses have been preserved where possible.
          </span>
        </div>
        <div *ngIf="currentEngagement.status == 'RP rule engine error'" class="alert-danger col-md-12">
          <span class="msg-icon">
            <img src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="Failed to load Decisions RPs">
          </span>
          <span class="d-inline-block align-middle msg-text">
            The rules used to determine the appropriate Reviewing professionals have encountered an error. Please click
            on 'Edit questionnaire' and proceed to Review screen to attempt to resolve the error. Please contact
            <a class='canvas-link' (click)="OpenEngageSupportCanvasLink()" rel="noopener noreferrer">Engage support</a>
            with the url for the engagement if the issue persists.
          </span>
        </div>
        <div
          *ngIf="(ReviewInProgressHDSession || ReviewInProgressEditEngagement ) && workspaceModel.status != 'Declined' && workspaceModel.status != 'Abandoned' && workspaceModel.status != 'Approved'"
          class="alert-warning col-md-12">
          <span class="msg-icon">
            <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Inprogress session">
          </span>
          <span class="d-inline-block align-middle msg-text">
            This engagement is currently being edited by {{reviewInProgressEditEngagementMembers}}
          </span>
        </div>

      </div>
      <div class="row mx-0 my-3">
        <div class="col-md-12 col-lg-9  pl-0 px-0-tablet">
          <div class="row m-0">
            <div class="col-md-12 bg-edp-body p-3 px-0-tablet">
              <!-- document selection drop down -->
              <div class="row p-0 ml-0" id="drpDownWorkspaceDocument"
                *ngIf="workspaceDocumentCount > 1  && currentEngagement.appliedTnC !== true && engagementState !== 'ChangeOrder' ">
                <div id="delDocumentBtn" class="col-md-8 col-lg-8 p-0">
                  <div class="selectdiv w-75 float-left">
                    <select class="dropdown rounded-0" id="ddWorkspaceDocument" name="ddWorkspaceDocument"
                      (change)="WorkspaceDocumentChange()">
                      <option *ngFor="let item of workspaceDocumentList" [value]="item.workspaceDocumentId">
                        {{item.documentAliasName}}
                      </option>
                    </select>
                  </div>
                  <a class="float-right mt-2 removeLink"
                    *ngIf="selectedWorkspaceDocumentItem.isPrimaryWorkspaceId === false"
                    (click)="confirmModalToremoveSecondaryWorkspace();">
                    Delete document
                  </a>
                </div>
              </div>

              <div class="row mb-2 ml-0" id="divworkspaceDocument"
                *ngIf="workspaceDocumentCount == 1 || currentEngagement.appliedTnC === true || engagementState === 'ChangeOrder' ">
                <span class="font-weight-bold">{{selectedWorkspaceDocumentItem.documentAliasName}}</span>
              </div>
              <!-- End drop down -->
              <div role="tabpanel" class="tabpanel">
                <!-- Nav tabs -->
                <div class="DocPreviewTabs">
                  <ul class="nav nav-tabs " role="tablist">
                    <li class="nav-item">
                      <a href="#EL" class="nav-link" aria-controls="EL" role="tab" data-toggle="tab" id="documentName"
                        #documentName="ngModel" [(ngModel)]="selectedWorkspaceDocumentItem.documentAliasName"
                        (click)="PreviewDocument(selectedWorkspaceDocumentItem.workspaceId, selectedWorkspaceDocumentItem.documentId, selectedWorkspaceDocumentItem.workItemId)">
                        Document preview
                        <label style="display: none;">{{selectedWorkspaceDocumentItem?.documentId}}</label>
                      </a>
                    </li>
                    <li role="presentation" class="nav-item sec-tab">
                      <a href="#ReviewReponses" class="nav-link" aria-controls="#ReviewReponses" role="tab"
                        data-toggle="tab" id="reviewReponseNavItem" (click)="QuestionSummaryDisplay()">
                        <span *ngIf="currentEngagement.appliedTnC === true">Revised responses</span>
                        <span *ngIf="currentEngagement.appliedTnC !== true">Review responses</span>
                      </a>
                    </li>
                    <li *ngIf="currentEngagement.appliedTnC === true" role="presentation" class="nav-item sec-tab">
                      <a href="#OriginalReviewReponses" class="nav-link" aria-controls="#OriginalReviewReponses"
                        role="tab" data-toggle="tab" id="OriginalreviewReponseNavItem"
                        (click)="QuestionSummaryDisplayForOriginal()">Original responses</a>
                    </li>

                    <li role="presentation" class="nav-item sec-tab">
                      <a href="#ReviewEngagement" class="nav-link" (click)="displayReviewEngagement()"
                        aria-controls="#ReviewEngagement" role="tab" id="reviewEngagementAssignment"
                        data-toggle="tab">Review
                        engagement</a>
                    </li>
                  </ul>
                </div>

                <!-- Tab panes -->
                <div class="tab-content panel-box-shadow  ">
                  <div role="tabpanel" class="tab-pane  active" id="EL">
                    <div class="embed-responsive DocumentPreviewIFrame">
                      <iframe id="DocumentPreview" SameSite="None" title="Document" class="embed-responsive-item ">
                        This is an embedded
                        <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a>
                        document, powered by <a target='_blank' href='http://office.com/webapps'
                          rel="noopener noreferrer">
                          Office Online</a>.</iframe>
                    </div>
                  </div>
                  <div role="tabpanel" class="tab-pane" id="ReviewReponses">
                    <div class="ReviewResponsesSection">
                      <div class="ReviewResponsesSectionContainer">
                        <app-review-response [hdWorkItemVersionId]="workItemVersionIdReviewResponse"
                          [hdWorkItemId]="workItemIdForReviewResponse" [isAppliedTnC]="false"
                          (OnQuestionAnswerLoaded)="OnHDQuestionAnswerLoaded($event)">
                        </app-review-response>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="currentEngagement.appliedTnC === true " role="tabpanel" class="tab-pane"
                    id="OriginalReviewReponses">
                    <div class="ReviewResponsesSection">
                      <div class="ReviewResponsesSectionContainer">
                        <app-review-response [hdWorkItemVersionId]="originalworkItemVersionIdReviewResponse"
                          [hdWorkItemId]="originalworkItemIdForReviewResponse" [isAppliedTnC]="true"
                          (OnQuestionAnswerLoaded)="OnHDQuestionAnswerLoaded($event)">
                        </app-review-response>
                      </div>
                    </div>
                  </div>

                  <div role="tabpanel" class="tab-pane" id="ReviewEngagement">
                    <div class="ReviewResponsesSection">
                      <div class="ReviewResponsesSectionContainer bg-white">
                        <app-review-engagement [clientId]="clientId" [engagementId]="engagementId"
                          [lastUpdatedBy]="lastUpdatedByForReviewResponse">
                        </app-review-engagement>
                      </div>
                      <!-- The below iframe is hidden as it is used for Engage Summary Report. -->
                      <div class="d-none ReviewResponsesSection">
                        <app-summary-report [workspaceId]="workspaceIdForSummaryReport" [clmDetails]="clmDetails"
                          [siteUrl]="siteUrlForSummaryReport" (onGenerateStatus)="UpdateReviewResponseDisplay($event)">
                        </app-summary-report>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--tabpanel DocPreviewTabs-->

        </div>
        <!--end document preview-->
        <div class="col-md-12 col-lg-3 px-lg-0 align-items-stretch px-0-tablet">
          <div class="row m-0">
            <div class="col-12 col-md-12 p-0 group-tab-list">
              <div class="list-group" id="list-tab" role="tablist">
                <!-- <a class="list-group-item d-flex align-items-center list-group-item-action active"
                  id="list-review-notes-list" data-toggle="list" href="#list-review-notes" role="tab"
                  aria-controls="review-notes" (click)="HightlightSection('review-notes')">
                  <img id="review-notes" src="../../../assets/images/EDP/Workspace/library_add_check-24px-white.svg"
                    alt="Review notes" />&nbsp;Review notes
                  <span id="review-notes-badge"
                    class="ml-auto badge badge-pill badge-primary review_note-count">{{workspaceOpenReviewPointsCount}}</span>
                </a> -->
                <a class="list-group-item list-group-item-action" id="list-notification-only-professionals-list"
                  data-toggle="list" href="#list-notification-only-professionals" role="tab"
                  aria-controls="notification-only-professionals"
                  (click)="HightlightSection('notification-only-professionals')"
                  *ngIf="notificationOnlyProfessionals?.length > 0">
                  <img id="notification-only-professionals"
                    src="../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg"
                    alt="Notification only professionals" />&nbsp;Notification only professionals</a>
                <a class="list-group-item list-group-item-action" id="list-reviewing-professionals-list"
                  data-toggle="list" href="#list-reviewing-professionals" role="tab"
                  aria-controls="reviewing-professionals" (click)="HightlightSection('reviewing-professionals')">
                  <img id="reviewing-professionals" src="../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg"
                    alt="Reviewing
                professionals" />&nbsp;Reviewing
                  professionals</a>
                <a class="list-group-item list-group-item-action" id="list-team-members-list" data-toggle="list"
                  href="#list-team-members" role="tab" aria-controls="team-members"
                  (click)="HightlightSection('team-members')">
                  <img id="team-members" src="../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg"
                    alt="Team members" />&nbsp;Team
                  members</a>
                <a class="list-group-item list-group-item-action supplime-doc-ie active "
                  id="list-supplemental-documents-list" data-toggle="list" href="#list-supplemental-documents"
                  role="tab" aria-controls="supplemental-documents"
                  (click)="HightlightSection('supplemental-documents')">
                  <img id="supplemental-documents" src="../../../assets/images/EDP/Workspace/folder_open-24px-white.svg"
                    alt="Supplemental documents" />&nbsp;Supplemental
                  documents
                  <span
                    *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')"
                    class="ml-auto review_note-count float-right">
                    <img id="supplemental-document-sharepointurl"
                      src="../../../assets/images/EDP/Engagement/sharepoint-logo-white.svg"
                      alt="Supplemental sharepoint documents" (click)="OpenNewTab()" />
                  </span>
                  <span id="eng-siteRequestStatus"
                    class="ml-auto badge badge-pill bg-pink badge-primary review_note-count float-right"
                    *ngIf="!(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">
                    <img src="../../../../assets/images/EDP/Workspace/report_problem-24px.svg" class="icons-s-doc"
                      alt="MessageDialog" data-toggle="tooltip" data-html="true"
                      title='<div class=&quot;customTooltipPerm&quot;><p>Provisioning in progress.</p></div>'>
                  </span>
                </a>
                <!-- Perm Library-->
                <a class="list-group-item list-group-item-action perm-doc-ie " id="list-perm-documents-list"
                  data-toggle="list" href="#list-perm-library" role="tab" aria-controls="perm-library"
                  (click)="HightlightSection('perm-library','supplemental-sharepoint-documents')"
                  *ngIf="isPermLibraryFeatureFlagEnabled">
                  <img id="perm-library" src="../../../assets/images/EDP/Workspace/perm-library-icon-blue.svg"
                    alt="Perm Library" />&nbsp;Perm Library
                  <span
                    *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted') && !(currentEngagement.clientCode == '' || currentEngagement.clientCode == null)"
                    class="ml-auto review_note-count float-right">
                    <img id="perm-library-sharepointurl" src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg"
                      alt="Supplemental sharepoint documents" (click)="OpenNewTabForPermLibrary()" />
                  </span>
                  <span id="eng-siteRequestStatus"
                    class="ml-auto badge badge-pill bg-pink badge-primary review_note-count float-right"
                    *ngIf="!(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">
                    <img src="../../../../assets/images/EDP/Workspace/report_problem-24px.svg" class="icons-s-doc"
                      alt="MessageDialog" data-toggle="tooltip" data-html="true"
                      title='<div class=&quot;customTooltipPerm&quot;><p>Provisioning in progress.</p></div>'>
                  </span>
                  <span id="eng-siteRequestStatus"
                    class="ml-auto badge badge-pill bg-pink badge-primary review_note-count float-right"
                    *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted') && (currentEngagement.clientCode == '' || currentEngagement.clientCode == null)">
                    <img src="../../../../assets/images/EDP/Workspace/report_problem-24px.svg" class="icons-s-doc"
                      alt="MessageDialog" data-toggle="tooltip" data-html="true"
                      title='<div class=&quot;customTooltipPerm&quot;><p>A Perm Library will be created after the client code is received from CMS.</p></div>'>
                  </span>
                </a>
              </div>
            </div>
          </div>


          <div class="row m-0">
            <div class="col-md-12 px-0">
              <div class="tab-content" id="nav-tabSectionContent">
                <!-- start review notes -->

                <!-- end review notes -->
                <!-- start of notifications only -->
                <div class="tab-pane fade mt-4" id="list-notification-only-professionals" role="tabpanel"
                  aria-labelledby="list-notification-only-professionals">
                  <div class="section-heading mb-2">
                    Notification only professionals
                  </div>
                  <div id="notificationOnlyProfessionals">
                    <div id="tblNOProfessionals" aria-describedby="RP"
                      *ngIf="notificationOnlyProfessionals?.length > 0">
                      <ul *ngFor="let roleGroup of notificationOnlyProfessionals"
                        class="reviewer-block nop-ul-no-bullets">
                        <div class="mb-2">{{ roleGroup.roleDescription }}</div>
                        <div *ngFor="let member of roleGroup.teamMembers">
                          <li class="my-1 reviewer">
                            <div>
                              <span class="d-inline-block align-top">
                                <img *ngIf="member.isNotified" src="assets/images/EDP/Workspace/check_circle-24px.svg"
                                  class="img-responsive" alt="approved" style="vertical-align: text-top;" width="20"
                                  height="20" />
                                <span *ngIf="!member.isNotified" class="reviewerStatus">
                                  <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                    class="Pending-status" />
                                </span>
                              </span>
                              <span
                                class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14">{{
                                member.memberName }}</span>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </div>
                    <div *ngIf="!notificationOnlyProfessionals || notificationOnlyProfessionals?.length == 0">
                      <span>
                        No Data Available
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end of notifications only -->
                <div class="tab-pane fade mt-4" id="list-reviewing-professionals" role="tabpanel"
                  aria-labelledby="list-reviewing-professionals">

                  <div id="epMemberInRPDiv" class="section-heading mb-2">
                    Engagement PPMD
                  </div>
                  <div id="EPDetailsInRP">
                    <div id="tblEPProfessionals" aria-describedby="RP" *ngIf="teamsMembersList.epMembers?.length > 0">
                      <ul *ngFor="let member of teamsMembersList.epMembers" class="reviewer-container">
                        <li class="reviewer">
                          <div>
                            <span class="d-inline-block align-top">
                              <img *ngIf="member.approvalStatus=='Approved'"
                                src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                              <span *ngIf="member.approvalStatus!='Approved'" class="reviewerStatus">
                                <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                  class="Pending-status" />
                              </span>
                            </span>
                            <span
                              class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14">
                              {{ member.memberName }}
                            </span>
                          </div>
                          <span *ngIf="member.isKeyTeamMember == true">
                            <img src="../../../assets/images/EDP/Workspace/icon-key.svg" alt="KeyTeamMember" />
                          </span>
                        </li>
                        <li class="ml-4 edp-font-12">
                          <span class="approve-stat" *ngIf="member.approvalStatus=='Approved'">
                            {{member.updatedOn | date: "MM/dd/yyyy"}}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="teamsMembersList.epMembers?.length == 0">
                      <span>
                        No Data Available
                      </span>
                    </div>
                  </div>

                  <div class="section-heading mb-2 pt-3">
                    Reviewing professionals
                  </div>
                  <div id="professionalsDetails" class="review-prof-panel">

                    <div id="tblProfessionals" aria-describedby="RP"
                      *ngIf="autoRPDisplayModel.length > 0 || manualRPDisplayModel.length > 0">

                      <ul *ngFor="let roleGroup of autoRPDisplayModel;let indexRpRole=index" class="reviewer-block">
                        <div class="mb-2">{{ roleGroup.roleDescription }}</div>
                        <div *ngFor="let member of roleGroup.teamMembers;let indexRp=index">
                          <li class="my-1 reviewer">
                            <div>
                              <span class="d-inline-block align-top">
                                <img *ngIf="member.approvalStatus=='Approved'"
                                  src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                  alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                                <span *ngIf="member.approvalStatus!='Approved'" class="reviewerStatus">
                                  <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                    class="Pending-status" />
                                </span>
                              </span>
                              <span
                                class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14">{{
                                member.memberName }}
                                <span *ngIf="member.isMemberDelegate == true" style="color: #707070"> (D) </span>
                              </span>
                            </div>
                            <div class="btn-group d-block" style="margin-top:-5px;" *ngIf="isLoggedInUserSystemAdmin &&
                            !(workspaceModel.status =='Declined' ||
                              workspaceModel.status =='Abandoned' ||
                              workspaceModel.status =='Approved' ||
                              member.approvalStatus =='Approved' ||
                              member.approvalStatus == 'NA')">
                              <a id="customDropdown" class="btn btn-light btn-sm customDropdown dropdown-toggle"
                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="assets/images/EDP/Engagement/more_horiz-24px.svg" alt="">
                              </a>
                              <div class="dropdown-menu" aria-labelledby="customDropdown">
                                <span class="dropdown-menu-arrow"></span>
                                <a style="font-size: 15px;" class="dropdown-item" href="#modal-addReviewer"
                                  data-toggle="modal" data-keyboard="false" data-backdrop="static"
                                  (click)="refreshModalReviewers('replace',indexRpRole,indexRp)">Replace RP</a>
                                <a style="font-size: 15px;" class="dropdown-item"
                                  (click)="confirmModalRemoveMember(member.id)">Remove</a>
                              </div>
                            </div>
                          </li>
                          <li class="ml-4 edp-font-12">
                            <span class="approve-stat" *ngIf="member.approvalStatus=='Approved'">
                              {{member.updatedOn | date: "MM/dd/yyyy"}}
                            </span>
                          </li>
                        </div>
                      </ul>
                      <div *ngFor="let roleGroup of manualRPDisplayModel" class="px-2">
                        <div class="mb-2">{{ roleGroup.roleDescription }} </div>
                        <ul *ngFor="let member of roleGroup.teamMembers" class="mb-1">
                          <li class="reviewer">
                            <div>
                              <span class="d-inline-block align-top">
                                <img *ngIf="member.approvalStatus=='Approved'"
                                  src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                  alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                                <span *ngIf="member.approvalStatus!='Approved'" class="reviewerStatus">
                                  <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                    class="Pending-status" />
                                </span>
                              </span>
                              <span
                                class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14">{{
                                member.memberName }}</span>
                            </div>
                            <a (click)="confirmModalRemoveMember(member.id)" data-toggle="modal" *ngIf="(IsLoggedInUserIsTM_EP || isLoggedInUserSystemAdmin || isLoggedInUserIsAutoOrManualRP) &&
                                  !(workspaceModel.status =='Declined' ||
                                    workspaceModel.status =='Abandoned' ||
                                    workspaceModel.status =='Approved' ||
                                    member.approvalStatus =='Approved' ||
                                    member.approvalStatus == 'NA')">
                              <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove" />
                            </a>
                          </li>
                          <li class="ml-4 edp-font-12">
                            <span class="approve-stat" *ngIf="member.approvalStatus=='Approved'">
                              {{member.updatedOn | date: "MM/dd/yyyy"}}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngIf="reviewerList.length == 0">
                      <span>
                        No Data Available
                      </span>
                    </div>
                  </div>
                  <div class="add-review-section mt-2"
                    *ngIf="!isWorkspaceApprovedOrRejected && (IsLoggedInUserIsTM_EP || isLoggedInUserSystemAdmin || isLoggedInUserIsAutoOrManualRP) && workspaceModel.status!='Declined' && workspaceModel.status!='Abandoned'">
                    <a class="" href="#modal-addReviewer" data-toggle="modal" data-keyboard="false"
                      data-backdrop="static" (click)="refreshModalReviewers('add',0,0)">
                      <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                      <span class="d-inline-block pl-2"> Add a reviewing professional</span>
                    </a>
                  </div>
                </div>
                <!-- Start Team Member Section (EP/Team Member/Client user)  -->
                <div class="tab-pane fade" id="list-team-members" role="tabpanel" aria-labelledby="list-team-members">
                  <div id="epMemberDiv" *ngIf="teamsMembersList?.epMembers?.length>0" class="section-heading pt-3">
                    Engagement PPMD
                  </div>
                  <div id="EPDetails">
                    <div id="tblEPProfessionals" aria-describedby="RP" *ngIf="teamsMembersList.epMembers?.length > 0">
                      <ul *ngFor="let member of teamsMembersList.epMembers" class="reviewer-container">
                        <li class="reviewer">
                          <div>
                            <span class="d-inline-block align-top">
                              <img *ngIf="member.approvalStatus=='Approved'"
                                src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                              <span *ngIf="member.approvalStatus!='Approved'" class="reviewerStatus">
                                <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                  class="Pending-status" />
                              </span>
                            </span>
                            <span
                              class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14">
                              {{member.memberName }}</span>
                          </div>
                          <span *ngIf="member.isKeyTeamMember == true">
                            <img src="../../../assets/images/EDP/Workspace/icon-key.svg" alt="KeyTeamMember" />
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="teamsMembersList.epMembers?.length == 0">
                      <span>
                        No Data Available
                      </span>
                    </div>
                  </div>
                  <div id="teamMemberDiv" *ngIf="teamsMembersList?.teamMembers?.length > 0"
                    class="section-heading pt-3">
                    Team members
                  </div>
                  <div id="teamMemberDetails" class="team-member-panel">
                    <div id="tblTeamMemberProfessionals" aria-describedby="RP"
                      *ngIf="teamsMembersList.teamMembers?.length > 0">
                      <ul *ngFor="let member of teamsMembersList.teamMembers" class="reviewer-container">
                        <li class="reviewer">
                          <div *ngIf="!isAudit">
                            <span class="d-inline-block align-top">
                              <img *ngIf="member.approvalStatus=='Approved' || member.approvalStatus=='Send to EPPMD'"
                                src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                              <span *ngIf="member.approvalStatus!='Approved' && member.approvalStatus!='Send to EPPMD'"
                                class="reviewerStatus">
                                <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                  class="Pending-status" />
                              </span>
                            </span>
                            <span
                              class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14">
                              {{ member.memberName }} ({{member.teamMemberRoleName}})
                            </span>
                          </div>
                          <div *ngIf="isAudit">
                            <div>
                              <span class="d-inline-block align-top">
                                <img *ngIf="member.approvalStatus=='Approved' || member.approvalStatus=='Send to EPPMD'"
                                  src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                  alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                                <span
                                  *ngIf="member.approvalStatus!='Approved' && member.approvalStatus!='Send to EPPMD'"
                                  class="reviewerStatus">
                                  <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                    class="Pending-status" />
                                </span>
                              </span>
                              <span
                                class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14">
                                {{ member.memberName }}
                              </span>
                            </div>
                            <div style="margin: -5px 0px 7px 0px;">
                              <span class="edp-font-14 pl-4" style="color: #707070; display: block;"
                                *ngIf="member.description =='' || member.description == null">
                                {{member.teamMemberRoleName}}
                              </span>

                              <span class="edp-font-14 pl-4"
                                style="color: #707070; display: block; word-break: break-all;"
                                *ngIf="member.teamMemberRoleName == 'Other' && member.description !='' && member.description != null">
                                {{member.teamMemberRoleName}} - {{ member.description }}
                              </span>
                            </div>
                          </div>
                          <div style="float: right; display: flex;">
                            <span *ngIf="member.isKeyTeamMember == true && isAudit" style="margin-top: -5px;">
                              <img src="../../../assets/images/EDP/Workspace/icon-key.svg" alt="KeyTeamMember" />
                            </span>
                            <a (click)="confirmModalRemoveTeamMember(member.id)" data-toggle="modal" *ngIf="member.teamMemberRoleName != 'Eng qual control reviewer (EQCR)' &&
                                          (IsLoggedInUserIsTM_EP || isLoggedInUserSystemAdmin || isReviewINDUSStaffingManagerHasAccess) &&
                                          !(workspaceModel.status =='Declined' ||
                                            workspaceModel.status =='Abandoned' ||
                                            workspaceModel.status =='Approved' ||
                                            member.approvalStatus =='Approved' ||
                                            member.approvalStatus =='Send to EPPMD')">
                              <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove" />
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="teamsMembersList.teamMembers?.length == 0">
                      <span>
                        No Data Available
                      </span>
                    </div>
                  </div>
                  <div class="add-review-section mt-2"
                    *ngIf="!isWorkspaceApprovedOrRejected  && (IsLoggedInUserIsTM_EP || isLoggedInUserSystemAdmin ||isReviewINDUSStaffingManagerHasAccess ) &&
                                  (idLoggedInUserHasWorkspaceAccess || isLoggedInUserSystemAdmin || isReviewINDUSStaffingManagerHasAccess) && workspaceModel.status!='Declined' && workspaceModel.status!='Abandoned'">
                    <a class="" href="#modal-TeamMember" data-toggle="modal" data-keyboard="false"
                      data-backdrop="static" class="" (click)="refreshTeamMemberPopup()">
                      <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                      <span class="d-inline-block pl-2"> Add team member</span>
                    </a>
                  </div>

                  <div id="clientTeamMemberDiv" style="display:none;" class="section-heading pt-3">
                    Client users
                  </div>

                  <div id="ClientTeamMemberDetails">
                    <div id="tblClientTeamMembers" aria-describedby="ClientTeamMembers"
                      *ngIf="teamsMembersList.clientTeamMemberList?.length > 0">
                      <ul *ngFor="let member of teamsMembersList.clientTeamMemberList let j = index;"
                        class="reviewer-container">
                        <li class="">
                          <div>
                            <span [ngClass]="(member.status =='Notified')?
                              'reviewer d-inline-block ml-4 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14' :
                              'd-inline-block ml-4 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14'">
                              {{member.firstName }} {{member.lastName }} </span>
                            <span style="color: #707070;">&nbsp;&nbsp;{{member.title}}</span>
                            <span class="error-color" *ngIf="member.status =='Error'">&nbsp;&nbsp;(Error)</span>
                            <span class="success-color" *ngIf="member.status =='Added'">&nbsp;&nbsp;(In progress)</span>
                          </div>

                          <a id="removeClientTeamMemberLink_{{j}}"
                            (click)="confirmModalToRemoveClientTeamMember(member.id)" data-toggle="modal" *ngIf="member.teamMemberRoleName != 'Eng qual control reviewer (EQCR)' &&
                                (IsLoggedInUserIsTM_EP || isLoggedInUserSystemAdmin || isReviewINDUSStaffingManagerHasAccess) &&
                                !(workspaceModel.status =='Declined' ||
                                workspaceModel.status =='Abandoned' ||
                                workspaceModel.status =='Approved' ||
                                member.approvalStatus =='Approved' ||
                                member.approvalStatus =='Send to EPPMD') &&
                                (member.status =='Notified' || member.status =='Error' )">
                            <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove" />
                          </a>

                        </li>
                      </ul>
                    </div>
                  </div>


                  <div class="add-review-section mt-2" *ngIf="!isWorkspaceApprovedOrRejected  && (IsLoggedInUserIsTM_EP || isLoggedInUserSystemAdmin ||isReviewINDUSStaffingManagerHasAccess ) &&
                                                                (idLoggedInUserHasWorkspaceAccess || isLoggedInUserSystemAdmin || isReviewINDUSStaffingManagerHasAccess) &&
                                                                workspaceModel.status!='Declined' &&
                                                                workspaceModel.status!='Abandoned'"
                    [class.nonClickableLink]="isMemberFirm">
                    <!-- <span [ngClass]="{'disabled':
                                        !(currentEngagement.siteRequestStatus ==='Completed' ||
                                        currentEngagement.siteRequestStatus ==='PartialCompleted')}"> -->
                    <a class="" href="#modal-addClientTeamMembers" data-toggle="collapse" data-keyboard="false"
                      data-backdrop="static" class="" (click)="refreshClientNewTeamMemberPopup()">
                      <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                      <span class="d-inline-block pl-2"> Add client user</span>
                    </a>

                    <!-- </span>    -->
                  </div>
                  <p></p>
                  <span id="clientErrorMessage" class="Errortext"></span>
                </div>
                <!-- End Team Member Section (EP/Team Member/Client user)  -->

                <!-- Start supplemental-documents -->
                <div class="tab-pane fade mt-2 show active" id="list-supplemental-documents" role="tabpanel"
                  aria-labelledby="list-supplemental-documents">
                  <div class="s-doc-section">
                    <div class="alert alert-danger my-3" role="alert"
                      *ngIf="!(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">
                      <div class="row m-0">
                        <div class="col-md-1 col-lg-2 col-xl-2 px-0 ">
                          <span id="review-notes-badge"
                            class="ml-auto badge badge-pill badge-primary review_note-count alert-pill-s-doc "><img
                              src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="No site Created"
                              class="icons-s-doc"></span>
                        </div>
                        <div
                          class="col-md-11 col-lg-10 col-xl-10 px-0 edp-font-13 font-weight-normal pl-1 l-h-14 pos-portrait">
                          This engagement’s site is
                          still being created.</div>
                      </div>
                    </div>
                    <div class=" mb-1"
                      *ngIf="isSharepointFileLabelFeatureFlagEnabled && (currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">
                      <label for="filterSharepointFileLabel">Filter by label: <span class="mandatory">*</span></label>
                      <div class="selectdiv">
                        <select class="dropdown rounded-0 " name="filterSharepointFileLabel" id="sharepointFileLabel"
                          [(ngModel)]="sharePointSelectedFileLabelForFilter">
                          <option [value]="'All'" selected>
                            All
                          </option>
                          <option [value]="'Unlabeled'">
                            Unlabeled
                          </option>
                          <option
                            *ngFor="let sharePointApplicationMasterLabel of sharePointApplicationMasterLabelsFiltered"
                            [value]="sharePointApplicationMasterLabel.value">
                            {{sharePointApplicationMasterLabel.value}}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="uploaded-file-s-doc"
                      *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">


                      <div id="tblsupplementalDocuments" class="my-3 scroll-s-doc"
                        *ngIf="supplementalDocumentList.length > 0">
                        <ul
                          *ngFor="let document of supplementalDocumentList | filterSpfileLabel : sharePointSelectedFileLabelForFilter; let i=index"
                          class="mt-1 mb-1 pb-1">
                          <li class="my-0 reviewer">
                            <div>
                              <span class="d-inline-block align-top" style="width: 20px; text-align: center;">
                                <img src="../../../assets/images/EDP/Shortcuts/description_blue.svg" alt="Document Name"
                                  class="suup-doc-icon">
                              </span>

                              <span style="max-width:19ch;"
                                class="d-inline-block ml-1  font-weight-bold  l-spacing-1 edp-font-15 doc-filename"
                                title="{{document.documentName}}">
                                <a *ngIf="document.type == 'pdf'" href="{{document.documentUrl}}" target="_blank"
                                  class="cp">
                                  {{document.documentName}}
                                </a>
                                <a *ngIf="document.type == 'docx' || document.type =='doc'
                                                          || document.type =='pptx' || document.type =='ppt'
                                                          || document.type =='xlsx' || document.type =='xls'"
                                  href="{{document.documentEditUrl}}" target="_blank" class="cp">
                                  {{document.documentName}}</a>

                                <a *ngIf="document.type!='docx' && document.type!='doc'
                                                          && document.type!='pptx' && document.type!='ppt'
                                                          && document.type!='pdf' && document.type !='xlsx' && document.type !='xls'"
                                  href="{{document.documentUrl}}" target="_blank" class="cp">
                                  {{document.documentName}}
                                </a>
                              </span>

                            </div>
                            <div *ngIf="isSharepointFileLabelFeatureFlagEnabled==false">
                              <a *ngIf="(!isWorkspaceApprovedOrRejected && workspaceModel.status!='Declined' && workspaceModel.status!='Abandoned' &&
                               ((!isPureNotificationOnlyProfessional && idLoggedInUserHasWorkspaceAccess ) || isLoggedInUserSystemAdmin )
                               && currentEngagement.appliedTnC !== true)" (click)="confirmModalRemovSuppDoc(document)"
                                class="text-right float-right align-top"><img alt="remove"
                                  src="assets/images/EDP/Engagement/remove_circle_outline.svg"></a>
                            </div>
                            <div *ngIf="isSharepointFileLabelFeatureFlagEnabled && (!isWorkspaceApprovedOrRejected && workspaceModel.status!='Declined' && workspaceModel.status!='Abandoned' &&
                              ((!isPureNotificationOnlyProfessional && idLoggedInUserHasWorkspaceAccess ) || isLoggedInUserSystemAdmin )
                              )">
                              <div class="btn-group d-block">
                                <a id="sharePointLabelcustomDropdown" role="button" data-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false">
                                  <img src="assets/images/EDP/Engagement/more_horiz-24px.svg" alt="">
                                </a>
                                <div class="dropdown-menu" aria-labelledby="customDropdown">
                                  <span class="dropdown-menu-arrow"></span>
                                  <a class="dropdown-item edp-font-15" *ngIf="(!isWorkspaceApprovedOrRejected && workspaceModel.status!='Declined' && workspaceModel.status!='Abandoned' &&
                                      ((!isPureNotificationOnlyProfessional && idLoggedInUserHasWorkspaceAccess ) || isLoggedInUserSystemAdmin )
                                      && currentEngagement.appliedTnC !== true)"
                                    (click)="confirmModalRemovSuppDoc(document)">
                                    <!-- <img alt="remove" src="assets/images/EDP/Engagement/remove_circle_outline.svg">-->
                                    Delete</a>
                                  <a class="dropdown-item edp-font-15"
                                    (click)="displaySharepointFileLabelPopup(document)">Label</a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class=" edp-font-12"><span for="last-modifeid-date" class="last-mod-date">Last modified:
                              {{ document.modifiedDate | date:'MM/dd/yyyy'
                              }}</span></li>
                          <li class=" edp-font-12">
                            <div *ngIf="isSharepointFileLabelFeatureFlagEnabled">
                              <span *ngIf="document.label else fileLabel" class="doc-label">{{document.label}}</span>
                              <ng-template #fileLabel>
                                <button class="anchor-button" (click)="displaySharepointFileLabelPopup(document)" *ngIf="!isWorkspaceApprovedOrRejected && workspaceModel.status!='Declined' &&
                                workspaceModel.status!='Abandoned' &&
                                ((!isPureNotificationOnlyProfessional && idLoggedInUserHasWorkspaceAccess ) ||
                                 isLoggedInUserSystemAdmin || idLoggedInUserHasWorkspaceAccess) ">Add
                                  label</button>
                              </ng-template>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div *ngIf="supplementalDocumentList.length == 0">
                        <span>
                          No supplemental documents available
                        </span>
                      </div>

                      <hr>
                      <div id="uploadfileDiv" class="progress-uploding-doc-section my-3 d-none">
                        <div class="edp-font-16 font-weight-bold">
                          <img src="../../../assets/images/EDP/Interviews/uploading-animation.gif" alt="uploading"
                            class="uploading-proccess">
                        </div>
                        <div class="progress-uploding-doc">
                          <div class="doc-progress-uploding my-1 ">
                            <label class="mb-0 doc-filename" title="{{currentfileName}}">{{currentfileName}}</label>
                          </div>
                        </div>
                      </div>
                      <div class="file-upload text-sm-left text-lg-center ie-w-100" *ngIf="!isWorkspaceApprovedOrRejected && workspaceModel.status!='Declined' &&
                        workspaceModel.status!='Abandoned' &&
                        ((!isPureNotificationOnlyProfessional && idLoggedInUserHasWorkspaceAccess ) ||
                         isLoggedInUserSystemAdmin || idLoggedInUserHasWorkspaceAccess) ">
                        <label for="fileuploadlbl" (click)="AddNewFileClicked()"
                          class="btn-bs-file btn customBtnWhite rounded-0  mt-1 px-4 cursor-pointer ie-w-100">
                          <img src="../../../assets/images/EDP/Workspace/file-upload-doc-blue.svg"
                            alt="Attach  documents" class="doc-upload-icon1 ">
                          <span class="doc-lable-pos ml-2 font-weight-bold">Attach documents</span>
                        </label><input id="fileuploadlbl" hidden />
                        <label class="validationMessage text-danger" *ngIf="uploadFileValidationError">
                          File size is over the size limit or file name has special characters.
                        </label>
                      </div>
                      <input id="input-file" hidden type="file" (change)="onSelectFile($event.target.files)" />
                    </div>
                  </div>
                </div>
                <!-- End supplemental-documents -->


                <!-- Start Perm Library-documents -->
                <div class="tab-pane fade  mt-2 show " id="list-perm-library" role="tabpanel"
                  aria-labelledby="list-perm-library">
                  <div class="s-doc-section">
                    <div class="alert alert-danger my-3" role="alert"
                      *ngIf="!(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">
                      <div class="row m-0">
                        <div class="col-md-1 col-lg-2 col-xl-2 px-0 ">
                          <span id="review-notes-badge"
                            class="ml-auto badge badge-pill badge-primary review_note-count alert-pill-s-doc "><img
                              src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="No site Created"
                              class="icons-s-doc"></span>
                        </div>
                        <div
                          class="col-md-11 col-lg-10 col-xl-10 px-0 edp-font-13 font-weight-normal pl-1 l-h-14 pos-portrait">
                          This engagement’s site is
                          still being created.</div>
                      </div>
                    </div>
                    <div class="uploaded-file-s-doc"
                      *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">
                      <div id="tblsupplementalDocuments" class="my-3 scroll-s-doc"
                        *ngIf="permLibraryDocumentList.length > 0">
                        <!--<ul *ngFor="let document of supplementalDocumentList; let i=index">
                          <li class="reviewer">
                            <div>
                              <span class="d-inline-block align-top" style="width: 20px; text-align: center;">
                                <img src="../../../assets/images/EDP/Shortcuts/description_blue.svg" alt="Document Name"
                                  class="suup-doc-icon">
                              </span>
  
                              <span class="d-inline-block ml-1  font-weight-bold  l-spacing-1 edp-font-14 doc-filename"
                                title="{{document.documentName}}">
                                <a *ngIf="document.type == 'pdf'" href="{{document.documentUrl}}" target="_blank"
                                  class="cp">
                                  {{document.documentName}}
                                </a>
                                <a *ngIf="document.type == 'docx' || document.type =='doc'
                                          || document.type =='pptx' || document.type =='ppt'
                                          || document.type =='xlsx' || document.type =='xls'"
                                  href="{{document.documentEditUrl}}" target="_blank" class="cp">
                                  {{document.documentName}}</a>
  
                                <a *ngIf="document.type!='docx' && document.type!='doc'
                                          && document.type!='pptx' && document.type!='ppt'
                                          && document.type!='pdf' && document.type !='xlsx' && document.type !='xls'"
                                  href="{{document.documentUrl}}" target="_blank" class="cp">
                                  {{document.documentName}}
                                </a>
                              </span>
                              <a *ngIf="(!isWorkspaceApprovedOrRejected && workspaceModel.status!='Declined' && workspaceModel.status!='Abandoned' &&
               ((!isPureNotificationOnlyProfessional && idLoggedInUserHasWorkspaceAccess ) || isLoggedInUserSystemAdmin )
               && currentEngagement.appliedTnC !== true)" (click)="confirmModalRemovSuppDoc(document)"
                                class="text-right float-right align-top"><img alt="remove"
                                  src="assets/images/EDP/Engagement/remove_circle_outline.svg"></a>
                            </div>
                          </li>
                          <li class=" edp-font-12"><span for="last-modifeid-date" class="last-mod-date">Last modified:
                              {{document.modifiedDate}}</span></li>
                        </ul>-->
                      </div>


                      <!-- <div *ngIf="permLibraryDocumentList.length == 0">
                        <span>
                          No Perm Library Documents available
                        </span>
                      </div>
                      <hr>-->

                    </div>
                  </div>
                </div>
                <!-- End Perm Library-documents -->


              </div>
            </div>
          </div>
          <div class="col">

            <div class="col">

            </div>
          </div>
          <!--end review points-->

        </div>
      </div>
      <!-- start Action Buttons-->

      <!-- team member review buttons visibility -->
      <div class="row m-0 pt-3"
        *ngIf="(idLoggedInUserHasWorkspaceAccess && TeamMemberButtonSectionVisible) || (isLoggedInUserSystemAdmin && TeamMemberButtonSectionVisible)">
        <div id="btnTeamReviewContainer"
          class="col-md-12 col-lg-10 col-xl-9 pt-md-4 mt-md-2 mt-lg-0 pt-lg-0 px-lg-0 px-md-0">
          <div class="float-left w-sm-100">
              <button type="button" id="teamMemberbtnEditEngagement"
              [disabled]="isEngagmentRPRuleEngineError || currentEngagement.appliedTnC === true" 
              class="btn customBtnWhite  w-sm-46 mr-md-0 mr-lg-2"
              (click)="workspaceActionButtonClick('Edit Engagement', false)" data-toggle="modal" *ngIf= "engagementState !== 'ChangeOrder'">
              <span class="caret-left"></span>
              <span class="d-inline-block ml-2" style="vertical-align: super;">Edit engagement</span>
            </button>
       
              <button type="button" id="teamMemberbtnEditChangeOrder"
              [disabled]="isEngagmentRPRuleEngineError || currentEngagement.appliedTnC === true" 
              class="btn customBtnWhite  w-sm-46 mr-md-0 mr-lg-2" *ngIf= "engagementState === 'ChangeOrder'">
              <span class="caret-left"></span>
              <span class="d-inline-block ml-2" style="vertical-align: super;">Edit change order</span>
            </button>

        
            <button type="button" id="teamMemberbtnEditquestionnaire" class="btn customBtnWhite ml-md-3 mx-lg-2 w-sm-46"
              [disabled]="selectedWorkspaceDocumentItem.workspaceId === undefined" (click)="editQuestionnaireClick();"
              data-toggle="modal">
              <img src="assets/images/EDP/Engagement/caret-left-blue.svg" alt="caret-left"
                style="width: 18px;height:18px;">
              <span class="d-inline-block ml-2 align-middle">Edit questionnaire</span>
            </button>
          </div>

          <div class="float-right text-right w-sm-100 text-md-left mt-md-2 mt-lg-0">

            <button type="button" (click)="workspaceActionButtonClick(AbandonedTitle, false)"
              class="btn customBtnWhite w-sm-46 mx-md-0  mx-lg-2" id="teamMemberbtnAbandon"
              [disabled]="isTMAbandon || isAbandonDisabled || currentEngagement.appliedTnC === true">
              <img class="abandonButtonIcon" alt="Abandon" style="width: 18px;height:18px;">

              <span class="d-inline-block ml-2 mr-2 align-middle">Abandon engagement</span>
              <img class="approveBtnIcon" alt="Tooltip" style="width: 18px;height:18px;" data-toggle="tooltip"
                data-html="true"
                title='<div class=&quot;customTooltip&quot;><b>Abandon Engagement</b> <br/> <p>Marks all documents as "read-only" and schedules the engagement for permanent deletion in 90 days.  This cannot be undone.</p></div>'>
            </button>
            <div class="form-control-feedback error-color" *ngIf="isTMAbandon">
              <p>Engagement abandoned</p>
            </div>
            <button type="button" id="btnSendToEPPMD"
              class="btn PrimaryButton rounded-0 edp-font-14 w-sm-46 ml-md-3 ml-lg-2"
              [disabled]="isSendToEPPMDBtnDisable || isEngagmentRPRuleEngineError ||!IsHDQuestionAnswerLoaded"
              (click)="workspaceActionButtonClick(SendToEPPMDTitle, false)" data-toggle="modal">
              <span class="d-inline-block ml-2 align-middle">Send to EPPMD</span>
              <img src="assets/images/EDP/Engagement/caret-right-white.svg" alt="caret-right"
                style="width: 18px;height:18px;">
            </button>

            <div [ngClass]="{true: 'loader', false: ''}[!IsHDQuestionAnswerLoaded && !isSendToEPPMDBtnDisable]"></div>
            <div class="form-control-feedback error-color text-right mr-md-2 mr-lg-0" *ngIf="shouldNotifyEPPMD">
              <p>EPPMD notified</p>
            </div>
          </div>
        </div>

      </div>
      <!-- end team member review buttons visibility -->

      <!-- start EPPMD review stage buttons visibility -->
      <div class="alert-forRP col-md-12 col-lg-12" *ngIf="workspaceModel.status == RPInReviewStatus && 
      !isLoggedInUserSystemAdmin && isUserRP && !isUserEP">
        <span class="msg-icon">
          <img src="assets/images/EDP/Workspace/report_problem_warningRP.svg" alt="Inprogress session">
        </span>
        <span class="d-inline-block align-middle msg-text">
          Reviewing professionals cannot update the engagement/questionnaire when the engagement has a status of "RP
          review."
        </span>
      </div>
      <div class="row m-0 pt-3 pt-lg-4 " *ngIf="idLoggedInUserHasWorkspaceAccess &&
             !TeamMemberButtonSectionVisible &&  EPPMDButtonSectionVisible ">
        <div id="btnEditReviewContainer"
          class="col-md-12 col-lg-9  pl-0 pt-md-4 mt-md-2 mt-lg-0 pt-lg-0 px-md-0 px-lg-0">

          <button type="button" id="EPPMDbtnEditEngagement" class="btn customBtnWhite mr-2 w-sm-30"
            [disabled]="isEngagmentRPRuleEngineError || currentEngagement.appliedTnC === true"
            (click)="workspaceActionButtonClick('Edit Engagement', false)" data-toggle="modal">
            <img *ngIf="!isEngagmentRPRuleEngineError && !currentEngagement.appliedTnC"
              src="assets/images/EDP/Engagement/caret-left-blue.svg" alt="caret-left" style="width: 18px;height:18px;">
            <img *ngIf="isEngagmentRPRuleEngineError || currentEngagement.appliedTnC"
              src="assets/images/EDP/Engagement/white-left-arrow.svg" alt="caret-left" style="width: 18px;height:18px;">
            <span class="d-inline-block ml-2 align-middle">Edit engagement</span>
          </button>

          <button type="button" id="EPPMDbtnEditquestionnaire" class="btn customBtnWhite mx-2  w-sm-30"
            [disabled]="selectedWorkspaceDocumentItem.workspaceId === undefined"
            (click)="workspaceActionButtonClick(EditQuestionnaireTitle, false)" data-toggle="modal">
            <img src="assets/images/EDP/Engagement/caret-left-blue.svg" alt="caret-left"
              style="width: 18px;height:18px;">
            <span class="d-inline-block ml-2 align-middle">Edit questionnaire</span>
          </button>

          <button type="button" id="EPPMDbtnAbandon" (click)="workspaceActionButtonClick(AbandonedTitle, false)"
            class="btn customBtnWhite  w-sm-30  mr-md-0 mx-2"
            [disabled]="isTMAbandon || currentEngagement.appliedTnC === true">
            <img class="abandonButtonIcon" alt="Abandon" style="width: 18px;height:18px;">
            <span class="d-inline-block ml-2 mr-2 align-middle">Abandon engagement</span>
            <img class="approveBtnIcon" alt="Tooltip" style="width: 18px;height:18px;" data-toggle="tooltip"
              data-html="true"
              title='<div class=&quot;customTooltip&quot;><b>Abandon Engagement</b> <br/> <p>Marks all documents as "read-only" and schedules the engagement for permanent deletion in 90 days.  This cannot be undone.</p></div>'>
          </button>
          <div class="form-control-feedback error-color text-right" *ngIf="isEPPMDAbandon">
            <p>Engagement abandoned</p>
          </div>
        </div>
        <div id="btnDecAppReviewContainer" class="col-md-12 col-lg-3  px-lg-0 px-md-0  mt-md-3 mt-lg-0">
          <button type="button" id="EPPMDbtnDecline" class="btn rpepdecline customBtnRed edp-font-14 w-sm-46 "
            [disabled]="isEPPMDApproveBtnDisable || isEngagmentRPRuleEngineError"
            (click)="workspaceActionButtonClick(EPDeclinedTitle, true)">
            <img src="assets/images/EDP/Workspace/decline_white.svg" alt="Decline" style="width: 18px;height:18px;">
            <span class="d-inline-block ml-1 align-middle">Decline</span>
            <img class="declineBtnIcon" alt="Tooltip" style="margin-left:4px; width: 18px;height:18px;"
              data-toggle="tooltip" data-html="true"
              title="<div class=&quot;customTooltip&quot;><b>Decline</b> <br/> <p>Permanently rejects the engagement and prevents further action by any team member or reviewer.  This cannot be undone.</p></div>">
          </button>

          <button type="button" id="EPbtnApproved" class="btn primaryapprove PrimaryButton ml-2 edp-font-14 w-sm-46"
            [disabled]="isEPPMDApproveBtnDisable || !IsHDQuestionAnswerLoaded || isEngagmentRPRuleEngineError"
            (click)="workspaceActionButtonClick(EPApprovedTitle, false)" data-toggle="modal">
            <img src="assets/images/EDP/Workspace/check_box-24px-white.svg" alt="Approval"
              style="margin-left:-6px; width: 18px;height:18px;">
            <span class="d-inline-block ml-1 align-middle">Approve</span>
            <img class="approveBtnIcon" alt="Tooltip" style="margin-left:4px; width: 18px;height:18px;"
              data-toggle="tooltip" data-html="true"
              title="<div class=&quot;customTooltip&quot;><b>Approve</b> <br/> <p>Records your approval of this engagement.</p></div>">
          </button>
          <div [ngClass]="{true: 'loader', false: ''}[!IsHDQuestionAnswerLoaded && !isEPPMDApproveBtnDisable]"></div>
          <div class="form-control-feedback error-color" *ngIf="isEPPMDApprove && !this.isLoggedInUserFinalApprover">
            <p class="mr-md-2 mr-lg-0">Reviewing Professionals notified</p>
          </div>
        </div>
      </div>
      <!-- end EPPMD review stage buttons visibility -->

      <!-- start RP review stage buttons visibility CurrentUser only RP-->

      <div [ngClass]="{true: 'row m-0 pt-3 pt-lg-4 btnDiv', false: 'row m-0 pt-3 btnDiv'}[isEPPMDAsRP]" *ngIf="idLoggedInUserHasWorkspaceAccess &&
          !TeamMemberButtonSectionVisible &&  !EPPMDButtonSectionVisible &&  RPButtonSectionVisible ">


        <div class="col-md-12 col-lg-9 pl-0 pt-md-4 mt-md-2 mt-lg-0 pt-lg-0 px-md-0 px-lg-0">
          <button *ngIf="isEPPMDAsRP || (isLoggedInUserSystemAdmin && isUserRP)" type="button"
            id="RPAsEPPMDbtnEditEngagement" class="btn customBtnWhite mr-2 w-sm-30"
            (click)="workspaceActionButtonClick('Edit Engagement', false)" data-toggle="modal"
            [disabled]="currentEngagement.appliedTnC === true">
            <img *ngIf="!isEngagmentRPRuleEngineError && !currentEngagement.appliedTnC"
              src="assets/images/EDP/Engagement/caret-left-blue.svg" alt="caret-left" style="width: 18px;height:18px;">
            <img *ngIf="isEngagmentRPRuleEngineError || currentEngagement.appliedTnC"
              src="assets/images/EDP/Engagement/white-left-arrow.svg" alt="caret-left" style="width: 18px;height:18px;">
            <span class="d-inline-block ml-2 align-middle">Edit engagement</span>
          </button>

          <button *ngIf="isEPPMDAsRP || (isLoggedInUserSystemAdmin && isUserRP)" type="button"
            id="RPAsEPPMDbtnEditquestionnaire" class="btn customBtnWhite mx-2  w-sm-30"
            (click)="workspaceActionButtonClick(EditQuestionnaireTitle, false)" data-toggle="modal">
            <img src="assets/images/EDP/Engagement/caret-left-blue.svg" alt="caret-left"
              style="width: 18px;height:18px;">
            <span class="d-inline-block ml-2 align-middle">Edit questionnaire</span>
          </button>

          <button *ngIf="isEPPMDAsRP || (isLoggedInUserSystemAdmin && isUserRP)" type="button" id="RPAsEPPMDbtnAbandon"
            (click)="workspaceActionButtonClick(AbandonedTitle, false)"
            class="btn customBtnWhite  w-sm-30  mr-md-0 mx-2"
            [disabled]="isTMAbandon || currentEngagement.appliedTnC === true">
            <img class="abandonButtonIcon" alt="Abandon" style="width: 18px;height:18px;">

            <span class="d-inline-block ml-1 mr-2 align-middle">Abandon engagement</span>
            <img class="approveBtnIcon" alt="Tooltip" style="width: 18px;height:18px;" data-toggle="tooltip"
              data-html="true"
              title='<div class=&quot;customTooltip&quot;><b>Abandon Engagement</b> <br/> <p>Marks all documents as "read-only" and schedules the engagement for permanent deletion in 90 days.  This cannot be undone.</p></div>'>

          </button>
          <div class="form-control-feedback error-color text-right" *ngIf="isEPPMDAbandon">
            <p>Engagement abandoned</p>
          </div>

          <button type="button" id="RPAsEPPMDbtnClose" (click)="workspaceCloseButtonClick()"
            class="btn closeRP rpclose customBtnWhite float-right  w-sm-30  mr-md-3 mx-2">
            <img src="assets/images/EDP/Workspace/close_blue_24dp.svg" alt="Close" style="width: 18px;height:18px;">
            <span class="d-inline-block ml-1 align-middle">Close</span>
            <img src="assets/images/EDP/Workspace/help_outline-24px.svg" alt="Abandon"
              style="margin-left:4px; width: 18px;height:18px;" data-toggle="tooltip" data-html="true"
              title="<div class=&quot;customTooltip&quot;><b>Close</b> <br/> <p>Return to the dashboard and continue my review later.</p></div>">
          </button>
        </div>
        <div
          [ngClass]="{true: 'col-md-12 col-lg-3 px-lg-0 px-md-0  mt-md-3 mt-lg-0', false: 'col-md-12 col-lg-3 px-lg-0 px-md-0  mt-md-3 mt-lg-0'}[isEPPMDAsRP]">

          <button type="button" id="RPbtnDecline" class="btn rpepdecline customBtnRed edp-font-14 w-sm-46"
            [disabled]="isRPApprovedBtnDisable || isEngagmentRPRuleEngineError || ReviewInProgressHDSession || ReviewInProgressEditEngagement"
            (click)="GetHDWorkItemDetails(RPDeclinedTitle, true)" data-toggle="modal">
            <img src="assets/images/EDP/Workspace/decline_white.svg" alt="Decline" style="width: 18px;height:18px;">
            <span class="d-inline-block ml-1 align-middle">Decline</span>
            <img class="declineBtnIcon" alt="Tooltip" style="margin-left:4px; width: 18px;height:18px;"
              data-toggle="tooltip" data-html="true"
              title="<div class=&quot;customTooltip&quot;><b>Decline</b> <br/> <p>Permanently rejects the engagement and prevents further action by any team member or reviewer.  This cannot be undone.</p></div>">
          </button>

          <button type="button" id="RPbtnApproved" class="btn primaryapprove PrimaryButton ml-2 edp-font-14 w-sm-46"
            [disabled]="isRPApprovedBtnDisable || !IsHDQuestionAnswerLoaded || isEngagmentRPRuleEngineError || ReviewInProgressHDSession || ReviewInProgressEditEngagement"
            (click)="GetHDWorkItemDetails(RPApprovedTitle, false, true)" data-toggle="modal">
            <img src="assets/images/EDP/Workspace/check_box-24px-white.svg" alt="Approval"
              style="margin-left:-6px;width: 18px;height:18px;">
            <span class="d-inline-block ml-1 align-middle">Approve</span>
            <img class="approveBtnIcon" alt="Tooltip" style="margin-left:4px;width: 18px;height:18px;"
              data-toggle="tooltip" data-html="true"
              title="<div class=&quot;customTooltip&quot;><b>Approve</b> <br/> <p>Records your approval of this engagement.</p></div>">
          </button>
          <div [ngClass]="{true: 'loader', false: ''}[!IsHDQuestionAnswerLoaded && !isRPApprovedBtnDisable]"></div>
          <div class="form-control-feedback error-color" *ngIf="isRPApproved">
            <p [ngClass]="{true: 'mr-md-2 mr-lg-0', false: 'text-right mr-md-2 mr-lg-0'}[isEPPMDAsRP]">Approval recorded
            </p>
          </div>
          <div *ngIf="ReviewInProgressHDSession || ReviewInProgressEditEngagement"
            class="col-md-12 m-0 mt-2 alert-warning ">
            <span class="msg-icon-RP-Warning col-md-3 p-0">
              <img src="assets/images/EDP/Workspace/report_problem_warning.svg" height="28" width="28"
                alt="Inprogress session">
            </span>
            <span class="d-inline-block align-middle col-md-9 p-0 pl-1 mw-98">
              Engagement cannot be approved or declined at this time because it is being edited by a team member.
            </span>
          </div>
        </div>

      </div>

      <div>
        <br />
        <app-alerts></app-alerts>
      </div>
      <!-- end RP review stage buttons visibility CurrentUser only RP-->


      <!-- end Action Buttons-->
      <div class="row topRow marginBottom10">

        <div class="col-md-3 discussionDiv">

        </div>

      </div>
      <!-- end row team details and discussionpanel-->
    </div>
  </div>

</div>
<ng-template #InProgressReviewScreen>
  <div>
    Loading Review Information...
  </div>
</ng-template>


<ng-template #AccessDenied>
  <div>
    <app-alerts></app-alerts>
  </div>
</ng-template>

<!--container fluid end-->

<!--modal popups-->

<div class="modal fade modal-window" id="modal-addReviewer">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select reviewing professional</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="ResetReviewer();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="" id="tblAddReviewer">
          <span id="ReviewerErrorMessage" class="ErrorFont"></span>
          <span id="ReplaceIndicatorMessage" class="" *ngIf="isReplaceRP"></span>
          <div class="input-group nav-searchbox-length pb-2">
            <input type="text" id="searchReviewerName" (keydown.enter)="searchReviewerNames('searchReviewerName');"
              class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for reviewer" autocomplete="off">
            <span class="input-group-append nav-seachBox">
              <div class="input-group-text" (click)="searchReviewerNames('searchReviewerName');">
                <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
              </div>
            </span>
          </div>
          <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
          <div class="tableScroll mt-2">
            <table id="tblReviewerAD" class="table table-hover" aria-describedby="Reviewer">
              <thead>
                <tr>
                  <th scope="col"> Name</th>
                  <th scope="col">Email </th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Line of Business</th>
                  <th scope="col">Service Line</th>
                  <th scope="col">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr id="trReviewers" class="trReviewerlist" *ngFor="let reviewer of reviewersListArray; let i = index;"
                  (click)='selectReviewer(i,$event)'>
                  <td>
                    <span id='spReviewerDisplayName{{i}}'>{{reviewer.displayName}}</span>
                  </td>
                  <td>
                    <span id='spReviewerMail{{i}}'>{{reviewer.mail}}</span>
                  </td>
                  <td>
                    <span id='spReviewerTitle{{i}}'>{{reviewer.jobTitle}}</span>
                  </td>
                  <td>
                    <span id='spReviewerBusinessPhones{{i}}'>{{reviewer.businessPhones}}</span>
                  </td>
                  <td> <span id='splineOfBusiness{{i}}'>{{reviewer.lineOfBusiness}}</span>
                  </td>
                  <td> <span id='spserviceLine{{i}}'>{{reviewer.serviceLine}}</span>
                  </td>
                  <td> <span id='splocation{{i}}'>{{reviewer.location}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer text-right">
        <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
          style="width: 150px; text-align: center; " (click)="ResetReviewer();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button [disabled]="!isReplaceRpOkButtonEnabled" type="button" class="PrimaryButton"
          (click)="AddReviewer(reviewer)" style="width: 150px; text-align: center; ">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-addTeamMember">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select team member</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
          &times;
        </button>
      </div>
      <div class="modal-body container peoplePickerBody">
        <div id="tbladdTeamMember">
          <span id="TMErrorMessage" class="ErrorFont"></span>
          <input type="text" id="searchMemberName" (keyup)="searchMemberNames($event);" class="form-control"
            placeholder="Search for team member">
          <br>
          <div class="tableScroll">
            <table id="tblMemberAD" class="table  table-hover" aria-describedby="Team Members">
              <thead>
                <tr>
                  <th scope="col"> Name</th>
                  <th scope="col">Email </th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Line of Business</th>
                  <th scope="col">Service Line</th>
                  <th scope="col">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr id="trMemberlist" class="trMemberlist" *ngFor="let member of membersListArray; let i = index;"
                  (click)='selectMember(i,$event)'>
                  <td>
                    <span id='spMemberdisplayName{{i}}'>{{member.displayName}}</span>
                  </td>
                  <td>
                    <span id='spMembermail{{i}}'>{{member.mail}}</span>
                  </td>
                  <td>
                    <span id='spMembertitle{{i}}'>{{member.jobTitle}}</span>
                  </td>
                  <td>
                    <span id='spbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                  </td>
                  <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                  </td>
                  <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                  </td>
                  <td> <span id='splocation{{i}}'>{{member.location}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <table class="table" aria-describedby="Team Member Roles">
          <tbody>
            <tr>
              <td>
                <form #AddMemberForm="ngForm">
                  <select class="form-control" id="ddTeamMemberRole" #TeamMemberRole="ngModel" name="ddTeamMemberRole"
                    required="required" [(ngModel)]="teamMember.roleId">
                    <option [ngValue]='null'>-- Select Team Member Role --</option>
                    <option *ngFor="let role of workspaceteamMemberRoleList" value="{{role.id}}">
                      {{role.typeValue}}
                    </option>
                  </select>
                  <br>
                  <br>
                  <button type="submit" class="btn PrimaryButton" [disabled]="!AddMemberForm.form.valid"
                    (click)="AddMember(teamMember)">Add</button>&nbsp;
                  <button type="button" class="SecondaryButton" data-dismiss="modal">
                    Cancel
                  </button>
                </form>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- <app-activity-log [workspace]="workspaceIdForActivityLog" (cleared)="onCleared($event)"></app-activity-log> -->

<div class="modal fade" id="modal-addReviewPoints">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title"> Add Review Point</span>
        <button type="button" class="close" (click)="CancelReviewPoints()" aria-hidden="true">
          &times;
        </button>
      </div>
      <form #addReviewPointForm="ngForm">
        <div class="modal-body">
          <div class="form-group">
            <label id="lblSub">Subject: </label>
            <input type="text" name="txtSubject" required maxlength="1000"
              [(ngModel)]="currentworkspaceReviewPoints.subject" id="txtSubject" #subject="ngModel" class="form-control"
              placeholder="Enter Subject" (blur)="checkSpacesatBegining($event,0)" />
          </div>
          <div class="form-group">
            <label id="lblDescr">Description: </label>
            <textarea class="form-control" required maxlength="3000" #desc="ngModel"
              (blur)="checkSpacesatBegining($event,0)" [(ngModel)]="currentworkspaceReviewPoints.description"
              name="txtDescr" id="txtDescr" rows="3" placeholder="Enter Description"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <table class="table" aria-describedby="Buttons">
            <tbody>
              <tr>
                <td>
                  <button type="button" class="PrimaryButton" [disabled]="!addReviewPointForm.form.valid"
                    (click)="InsertReviewPoints()">
                    Save</button>&nbsp;
                  <button type="button" class="SecondaryButton" (click)="CancelReviewPoints()">
                    Cancel
                  </button>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-ViewReviewPoints">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title"> View Review Point</span>
        <button type="button" class="close" (click)="CloseViewReviewPointModal()" aria-hidden="true">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label id="lblSub">Subject: </label>
          <label id="ReviewTypeId" style="display:none">{{
            currentworkspaceReviewPoints.reviewPointId
            }}</label>
          <span id="Subject" class="label label-info">
            {{ currentworkspaceReviewPoints.subject }}
          </span>
        </div>
        <div class="form-group">
          <label id="lblDescr">Description: </label>
          <span class="label label-info">
            {{ currentworkspaceReviewPoints.description }}
          </span>
        </div>
        <div class="form-group">
          <label class="lblStatus"><strong>Status:</strong> </label>
          <span class="spStatus" *ngIf="currentworkspaceReviewPoints.status == 1">
            Open</span>
          <span class="spStatus StatusResolved" *ngIf="currentworkspaceReviewPoints.status == 2">
            Resolved</span>
        </div>
        <div *ngIf="this.isLoggedinUserCreatedby == true">
          <span class="" *ngIf="currentworkspaceReviewPoints.status == 1">
            <button class="btnResolve" type="button" (click)="ResolveReviewPoint()">
              Resolve
            </button>
          </span>
        </div>
        <hr />
        <label id="lblDescr">Comments ({{ workspaceReviewPointComments?.length }})
        </label>
        <table class="table" id="tblReviewComments" aria-describedby="Review Comments">
          <tbody>
            <tr *ngFor="let reviewPointComments of workspaceReviewPointComments">
              <td id="tdImg">
                <ngx-avatars name="{{reviewPointComments.name}}" size="30" textSizeRatio="2" round="true"
                  title="{{reviewPointComments.name}}">
                </ngx-avatars>
              </td>
              <td>
                <ul>
                  <li>
                    <span class="discPersonName" class="font-weight-bold">{{
                      reviewPointComments.name
                      }}</span>
                    &nbsp;&nbsp;
                    <label class="reviewdate">
                      {{
                      reviewPointComments.createdOn
                      | date: "MM/dd/yyyy hh:mm a UTC"
                      }}
                    </label>
                  </li>
                  <li>
                    <label for="question">
                      {{ reviewPointComments.comments }}
                    </label>
                  </li>
                </ul>
              </td>
            </tr>
            <tr *ngIf="workspaceReviewPointComments?.length == 0">
              No comments available.
            </tr>
          </tbody>
        </table>
        <form #commentForm="ngForm" novalidate>
          <span class=""
            *ngIf="currentworkspaceReviewPoints.status == 1 && !isWorkspaceApprovedOrRejected && idLoggedInUserHasWorkspaceAccess">
            <div class="form-group">
              <div id="WriteComments" class="row">
                <div style="margin-left: 5px;"></div>
                <div class="col-md-10">
                  <input type="text" required (blur)="checkSpacesatBegining($event)" id="txtWriteComment"
                    name="txtWriteComment" #name="ngModel" [(ngModel)]="currentworkspaceReviewPointComment.comments"
                    class="form-control" />
                </div>
                <div>
                  <button type="button" id="btnSend" [disabled]="!commentForm.form.valid" (click)="
                      InsertReviewPointComments(
                        currentworkspaceReviewPointComment
                      )
                    " class="PrimaryButton">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </span>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-suppUploadDoc">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select a document</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
          &times;
        </button>
      </div>
      <form #modalAddSupplementDocForm="ngForm">
        <div class="modal-body">
          <span class="documentCategory">Category</span>
          <br />
          <div class="form-group">
            <select class="form-control col-md-6" id="SuppCategoryType" style="background-color: #f7f7f5">
              <option *ngFor="let category of modalSupplementalDocCategories" value="{{category.categoryId}}">
                {{category.categoryName}}</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div style="background-color: #f7f7f5;  border-radius: 4px;width: 307px;height: 69px;padding:5px">
                <span class="documentCategory">Select File</span><br />
                <span style="font-weight:bold;font-size:16px ">From your computer</span>
                <input #file type="file" accept='*' class="btnSupplBrowse" />
              </div>
            </div>
            <div class="col-md-6">
              <div style="background-color: #f7f7f5;  border-radius: 4px;width: 307px;height: 69px;padding:5px">
                <span class="documentCategory">Select File</span><br />
                <span style="font-weight:bold;font-size:16px ">From your Symphony</span>
                <button type="file" style="margin-left:20px;" class="btnSupplBrowse">
                  Browse
                </button>
              </div>
            </div>
          </div>
          <label class="font-weight-bold; padding:10px;">
            {{file.name}}
          </label>
        </div>
        <div class="modal-footer">
          <table class="table" aria-describedby="Buttons">
            <tbody>
              <tr>
                <td>
                  <button type="button" class="PrimaryButton" (click)="upload(file.files)"
                    [disabled]="!modalAddSupplementDocForm.form.valid">Upload</button>&nbsp;
                  <button type="button" class="SecondaryButton" data-dismiss="modal">
                    Cancel
                  </button>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- expand discussion popup -->
<div class="modal fade" id="modal-expandDiscussion">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Discussion</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div id="modal-discpanel" class="row">
          <div class="discLabel" style="width: 800px;">
            <span style="font-size: 14px;color: #74767a;">
              Visible to My Team and Counterparty
            </span>
            <!--start discussions comment section-->
            <div class="modal-discussions">
              <table class="table" style="width:600px;" aria-describedby="Workspace Discussion">
                <tr *ngFor="let modalwdisc of workspaceDiscussionList; index as i">
                  <td>
                    <ngx-avatars name="{{modalwdisc.userName}}" size="30" textSizeRatio="2" round="true"
                      title="{{modalwdisc.userName}}">
                    </ngx-avatars>
                  </td>
                  <td>
                    <ul style="margin-top: 5px;">
                      <li>
                        <span class="discPersonName" class="font-weight-bold">{{
                          modalwdisc.userName
                          }}</span>
                      </li>
                      <li>
                        <label class="discdate">{{
                          modalwdisc.createdon | date: "MM/dd/yyyy hh:mm a"
                          }}</label>
                      </li>
                      <li>
                        <label class="discdate">{{
                          modalwdisc.createdby
                          }}</label>
                      </li>
                      <li>
                        <label for="question">
                          {{ modalwdisc.discussion }}
                        </label>
                      </li>
                      <li>
                        <a id="reply{{modalwdisc.discussionId}}" *ngIf="modalwdisc.workspaceCommentsList.length > 0"
                          class="discreplies cp" data-toggle="collapse" href="#reply{{ modalwdisc.collapsibleId }}"
                          aria-expanded="false">{{
                          modalwdisc.workspaceCommentsList.length
                          }}
                          Replies </a>
                        <!-- replies details-->
                        <div [ngClass]="{
                          collapse: modalwdisc.workspaceCommentsList.length > 0
                        }" id="reply{{ modalwdisc.collapsibleId }}">
                          <table id="tblReplies" class="table" aria-describedby="Replies">
                            <tbody>
                              <tr *ngFor="let modalwcomm of modalwdisc.workspaceCommentsList">
                                <td>
                                  <ngx-avatars name="{{modalwcomm.userName}}" size="30" textSizeRatio="2" round="true"
                                    title="{{modalwcomm.userName}}">
                                  </ngx-avatars>
                                </td>
                                <td>
                                  <ul style="margin-top: 5px;">
                                    <li>
                                      <span class="discPersonName" class="font-weight-bold">{{ modalwcomm.userName
                                        }}</span>
                                    </li>
                                    <li>
                                      <label class="discdate">
                                        {{modalwcomm.createdon | date: "MM/dd/yyyy hh:mm a"}}
                                      </label>
                                    </li>
                                    <li>
                                      <label for="question">
                                        {{ modalwcomm.description }}
                                      </label>
                                    </li>
                                  </ul>
                                </td>
                              </tr>

                              <tr *ngIf="modalwdisc.workspaceCommentsList.length >= 0">
                                <td colspan="2">
                                  <form #modalAddDiscussionCommentForm="ngForm">
                                    <div id="discWrite" class="row"
                                      *ngIf="!isWorkspaceApprovedOrRejected && idLoggedInUserHasWorkspaceAccess">
                                      <div class="col-md-1"></div>
                                      <div class="col-md-9">
                                        <input type="text" id="txtWriteModalComment" name="txtWriteModalComment"
                                          required class="form-control" value="write reply" maxlength="1000"
                                          [(ngModel)]="
                                            modalWorkspaceComment.comments[i]"
                                          (blur)="checkSpaceChartBeginInTableRow($event,i,'comments')"
                                          #modalDescription="ngModel" />
                                      </div>
                                      <div class="col-md-1">
                                        <button type="button" id="btnSend" class="btn PrimaryButton" [disabled]="
                                            !modalAddDiscussionCommentForm.form
                                              .valid
                                          " (click)="
                                            AddWorkspaceComment(
                                              modalwdisc.discussionId,modalWorkspaceComment,modalWorkspaceComment.comments[i]
                                            )
                                          ">
                                          Send
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end replies-->
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
            <!--end discussions comment section-->

            <form #modalAddDiscussionForm="ngForm">
              <div id="discWrite" class="row"
                *ngIf="!isWorkspaceApprovedOrRejected && idLoggedInUserHasWorkspaceAccess">
                <div class="col-md-1"></div>
                <div class="col-md-8">
                  <input type="text" id="txtWriteModalDiscussion" name="txtWriteModalDiscussion" required
                    class="form-control" maxlength="3000" [(ngModel)]="modalWorkspaceDiscussion.discussion"
                    (blur)="checkSpaceChartBegin($event);" #modalDiscussion="ngModel" value="write a question" />
                </div>
                <div class="col-md-1">
                  <button type="button" id="btnSend" class="btn PrimaryButton"
                    [disabled]="!modalAddDiscussionForm.form.valid"
                    (click)="AddWorkspaceDiscussion(modalWorkspaceDiscussion)">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end expand discussion popup -->

<!-- expand review point popup -->
<div class="modal fade" id="modal-expandReviewPoints">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Review Points</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="expandReviews">
          <table class="table" id="tblExpandReviewPoints" aria-describedby="Expand Review Points">
            <tbody>
              <tr *ngFor="let reviewPoints of workspaceReviewPoints">
                <td>
                  <ngx-avatars name="{{reviewPoints.userName}}" size="30" textSizeRatio="2" round="true"
                    title="{{reviewPoints.userName}}">
                  </ngx-avatars>
                </td>
                <td>
                  <ul>
                    <li>
                      <span class="ReviewType" class="font-weight-bold">
                        {{reviewPoints.subject}}</span>
                    </li>
                    <li>
                      <label class="reviewdate">
                        {{reviewPoints.createdOn | date: "MM/dd/yyyy hh:mm a UTC"}}
                      </label>
                    </li>
                    <li>
                      <label for="question">{{ reviewPoints.name }}</label>
                      <span class="reviewStatus StatusOpen" *ngIf="reviewPoints.status == 1">Open</span>
                      <span class="reviewStatus StatusResolved" *ngIf="reviewPoints.status == 2"> Resolved</span>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end expand review point popup -->

<!--end modal popups-->
<div class="modal fade" id="modal-removeMember">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Remove team member</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h5>
          Do you want to remove a selected team member ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btnFooterAdd" data-dismiss="modal"
          (click)="removeMember(currentWorkespaceMemberId)">Yes</button>
        <button type="button" class="btnFooterCancel" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modalOpenReviewPointsForWorkspace">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Info</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h5>
          This workspace has some open review points you need to first resolve that, then you can perform this
          action.
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="PrimaryButton" data-dismiss="modal" (click)="CloseAlertPopup()">Ok</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modalCanTransferRP">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Info</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h5>
          Reviewing Professional you are transfering has some open review points. You cannot transfer.
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="PrimaryButton" data-dismiss="modal" (click)="CloseAlertPopup()">Ok</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="ErrorMessageForCannotAddEPAsReviewerModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Info</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>

      </div>
      <div class="modal-body">
        <h5>
          You cannot add reviewer which is already an Engagement Partner for this workspace.
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="PrimaryButton" data-dismiss="modal"
          (click)="CloseAddReviewerAlertPopup()">Ok</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="ApproveConfirmationModal">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Confirm</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        You are about to Approve this workspace, which cannot be reversed once completed. Do you wish to Approve
        this workspace?
      </div>
      <div class="modal-footer">
        <button type="button" class="PrimaryButton" id="ApproveButton"
          (click)="WorkspaceStatusUpdate('Approved')">Approve</button>
        <button type="button" class="SecondaryButton" id="CancelButton"
          (click)="cancelApproveReject('ApproveConfirmationModal')">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="RejectConfirmationModal">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Confirm</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        You are about to Reject this workspace, which cannot be reversed once completed. Do you wish to Reject this
        workspace?
      </div>
      <div class="modal-footer">
        <button type="button" class="PrimaryButton" id="RejectButton"
          (click)="WorkspaceStatusUpdate('Rejected')">Reject</button>
        <button type="button" class="SecondaryButton" id="CancelButton"
          (click)="cancelApproveReject('RejectConfirmationModal')">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="AbondonedConfirmationModal">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Confirm</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        You are about to permanently abandon this workspace, which cannot be reversed once completed. Do you wish to
        permanently abandon this workspace?
      </div>
      <div class="modal-footer">
        <button type="button" class="PrimaryButton" id="AbandonButton"
          (click)="WorkspaceStatusUpdate('Abandoned')">Abondon</button>
        <button type="button" class="SecondaryButton" id="CancelButton"
          (click)="cancelApproveReject('AbondonedConfirmationModal')">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="TransferReviewingProfessionalModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style="display:block">
        <span class="modal-title">Transfer Reviewing Professional For &nbsp;</span>
        <span class="modal-title nameForTitle"></span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="" id="tblTransferReviewer">
          <input type="text" id="searchTransferReviewerName" (keyup)="searchTransferReviewerNames($event);"
            class="form-control" placeholder="Search for reviewers">
          <div class="tableScroll">
            <table id="tblTransferReviewerAD" aria-describedby="Reviewer" class="table table-hover">
              <thead>
                <tr>
                  <th scope="col" width="40%"> Name</th>
                  <th scope="col" width="20%"> Email </th>
                  <th scope="col" width="40%"> Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr id="trReviewerlist" class="trReviewerlist"
                  *ngFor="let reviewer of reviewersTransferListArray; let i = index;"
                  (click)='selectTransferReviewer(i,$event)'>
                  <td>
                    <span id='spTransferReviewerdisplayName{{i}}'>{{reviewer.displayName}}</span>
                  </td>
                  <td>
                    <span id='spTransferReviewermail{{i}}'>{{reviewer.mail}}</span>
                  </td>
                  <td>
                    <span id='spTransferReviewerbusinessPhones{{i}}'>{{reviewer.businessPhones}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <table class="table" aria-describedby="Buttons">
          <tbody>
            <tr>
              <td>
                <button id="btnUpdateTransferReviewingProfessional" type="button" class="PrimaryButton" disabled
                  data-dismiss="modal" (click)="UpdateTransferReviewingProfessional(reviewer)">Update</button>&nbsp;
                <button type="button" class="SecondaryButton" data-dismiss="modal">
                  Cancel
                </button>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Start Template Version Check Confirmation  Modal  -->

<div class="modal fade" id="TemplateVersionCheckModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-lg">
        <h4 class="modal-title">Interviews</h4>
      </div>
      <div class="modal-body">
        <label class="hide" id="workspaceIdForTemplateVersionCheck"></label>
        <h5>New version of this document is released and questionnaire of latest version will show next which cannot
          be reverted to old version.
          Are you sure you want to proceed with Edit Questionnaire ?
        </h5><br>
      </div>
      <div class="modal-footer">
        <button class="PrimaryButton BtnVersionCheck" data-dismiss="modal" id="Yes" value="Yes">Yes</button>
        &nbsp;
        <button type="button" class="SecondaryButton" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
<div id="modal-TeamMember" class="modal fade modal-window">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select team member</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="ResetTeamMember();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="TeamMemberErrorMessage" class="ErrorFont"></span>
        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchTeamMemberName"
            (keydown.enter)="searchTeamMembers('searchTeamMemberName','tblTeamMemberAD');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for team member" autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchTeamMembers('searchTeamMemberName','tblTeamMemberAD');">
              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2" style="height: 135px;">
          <table id="tblTeamMemberAD" class="table  table-hover" aria-describedby="Members">
            <thead>
              <tr>
                <th id="TeamMemberName"> Name</th>
                <th id="TeamMemberEmail"> Email </th>
                <th id="TeamMemberJobTitle">Job Title</th>
                <th id="TeamMemberPhone">Phone</th>
                <th id="TeamMemberLOB">Line of Business</th>
                <th id="TeamMemberServiceLine">Service Line</th>
                <th id="TeamMemberLocation">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr id="trTeamMembers" name="trTeamMembers" *ngFor="let member of teamMembersListArray let i=index"
                (click)='selectTeamMember(i,$event)'>
                <td id="TeamMemberList{{i}}">
                  <span id='spMemberDisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td id="TeamMemberList{{i}}">
                  <span id='spTeamMemberMail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spTeamMemberJobTitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td id="TeamMemberList{{i}}"> <span id='spMemberBusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="isAudit" class="row mt-2">
          <div class="col-md-1 mt-4">
            <span><strong>Key</strong></span>
            <label class="custom-check-key edp-font-12">
              <input type='checkbox' name='chkKeyTeamMember' id='chkKeyTeamMember' [(ngModel)]="isKeyTeamMember"
                (change)="OnKeyTeamMemberChkboxChecked($event)">
              <span class="checkmark-custom-key"></span>
            </label>
          </div>
          <div class="col-md-3">
            <span> <strong> Role</strong></span>
            <div class="selectdiv" style="width: 90%;">
              <select id="drpKeyTeamMemberRole" name="KeyTeamMemberRole" class="form-control dropdown rounded-0"
                (change)="OnKeyRoleChange($event)" [(ngModel)]="KeyTeamMemberRoleId" required>
                <option value="0">Select role</option>
                <option id="drpKeyTeamMembersOptions" *ngFor="let role of keyteamMemberRoleFromAppMstList"
                  [value]=role.applicationMasterId>
                  {{role.value}}
                </option>
              </select>
            </div>
            <span id="KeyTeamMemberRoleErrorMessage" class="ErrorFont"></span>
          </div>
          <div class="col-md-4">
            <div class="keyDescription" id="keyTeamMemberDescription" style="display: none;">
              <span><strong>Description</strong></span>
              <textarea name="txtKeyDescription" id="txtKeyDescription" [(ngModel)]="KeyDescription"
                class="form-control rounded-0 h-30 edp-font-14  w-97" maxlength="100"
                style="resize: none; height: 40px;"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="ResetTeamMember();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;"
          (click)="AddTeamMember(member)">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- End Template Version Check Confirmation  Modal -->

<!-- Start Client Team Members Modal -->
<div class="modal fade modal-window" id="modal-addClientTeamMembers">
  <div class="modal-dialog">
    <div class="modal-content" style="min-height: auto !important;">
      <div class="modal-header">
        <span class="modal-title">Add client user</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="ResetReviewer();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>

      <div class="modal-body py-0 scroll-modal">
        <!-- start each client team details -->
        <div class="w-100 teamdetailsAll my-3" id="clientTeamdetails_0">
          <!-- <div class="mt-3 font-weight-bold">Client users</div> -->
          <table class="table table-borderless clientTeamDetails engSummaryTable table-popup">
            <thead class="border-bottom-0">
              <th scope="col1" class="border-bottom-0 w-td-1">First name <span class="mandatory">*</span></th>
              <th scope="col2" class="border-bottom-0 w-td-2">Last name <span class="mandatory">*</span></th>
              <th scope="col3" class="border-bottom-0 w-td-3">Email <span class="mandatory">*</span></th>
              <th scope="col4" class="border-bottom-0 w-td-4">Title</th>
              <th scope="col5" class="border-bottom-0 w-td-5">Site permissions<span class="mandatory">*</span>
              </th>
              <th scope="col6" class="txt-last border-bottom-0 w-td-6">
              </th>
            </thead>
            <tbody class="bg-edp-body edp-font-13">

              <hr class="d-none" id="divNewClientTeamMember_0" *ngIf="newClientTeamMemberList!=null
                    && newClientTeamMemberList.length > 0">
              <tr *ngFor="let clientTeamMember of newClientTeamMemberList;let t = index">
                <td>
                  <div class=" pl-2">
                    <input type="text" name="txtFirstName" id="txtFirstName_{{t}}" placeholder="First name"
                      class="form-control rounded-0 h-30 edp-font-12 w-97" [(ngModel)]="clientTeamMember.firstName"
                      autofocus>
                    <div>
                      <span id="errorLabelFirstName{{t}}" class="label error-color " style="display:none;">First name is
                        required</span>
                    </div>
                  </div>
                </td>
                <td class="td-sep2">
                  <div class="">
                    <input type="text" name="txtLastName" id="txtLastName_{{t}}" placeholder="Last name"
                      class="form-control rounded-0 h-30 edp-font-12  w-97" [(ngModel)]="clientTeamMember.lastName">
                    <div>
                      <span id="errorLabelLastName{{t}}" class="label error-color " style="display:none;">Last name is
                        required</span>
                    </div>

                  </div>
                </td>
                <td class="td-sep2">
                  <div class="">
                    <input type="text" autocomplete="no-fill" formControlName="confirmpass"
                      (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"
                      name="txtClientEmail" id="txtClientEmail_{{t}}" placeholder="Email"
                      class="form-control rounded-0 h-30 edp-font-12  w-97" [(ngModel)]="clientTeamMember.emailId"
                      maxlength="50"
                      (blur)="checkSpaceChartBeginInTableRow($event,t,'txtClientEmail_');ValidateClientEmailField(t);">
                    <div>
                      <span id="errorLabelEmail{{t}}" style="display:none;" class="label error-color">
                        Client email is required </span>
                    </div>
                    <div class="label error-color"
                      *ngIf="!(arrValidationForNewClientTeamMember[t].emailId == null || arrValidationForNewClientTeamMember[t].emailId=='') && arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail">
                      Client email should be in valid format.
                    </div>
                    <div class="label error-color"
                      *ngIf="!(arrValidationForNewClientTeamMember[t].emailId == null || arrValidationForNewClientTeamMember[t].emailId=='') && arrValidationForNewClientTeamMember[t].isNewUserDuplicate">
                      <p>Email already exists! Please enter unique email </p>
                    </div>
                    <span id="userExist{{t}}" class="label error-color"
                      *ngIf="arrValidationForNewClientTeamMember[t].cmsDataMatchClient ">User
                      already exists!</span>
                    <br>
                    <input type="email" autocomplete="no-fill" formControlName="confirmpass"
                      (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"
                      name="txtconfirmClientEmail_{{t}}" id="txtconfirmClientEmail_{{t}}" placeholder="Confirm Email"
                      (blur)="ValidateConfirmClientEmailField(t)" class="form-control rounded-0 h-30 edp-font-12  w-97"
                      [(ngModel)]="clientTeamMember.clientEmailId" maxlength="50">
                    <div>
                      <span id="errorLabelConfirmEmail{{t}}" style="display:none;"
                        class="label error-color edp-font-12">
                        <p>Confirm client email is required</p>
                      </span>
                    </div>
                    <div class="label error-color edp-font-12"
                      *ngIf="!(arrValidationForNewClientTeamMember[t].clientEmailId == null || arrValidationForNewClientTeamMember[t].clientEmailId=='') && arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail">
                      Confirm client email should be in valid format.
                    </div>
                    <div>
                      <span id="errorLabelConfirmEmailMatch{{t}}" style="display:none;"
                        class="label error-color edp-font-12">
                        <p>Emails do not match.</p>
                      </span>
                    </div>
                  </div>
                </td>
                <td class="td-sep2">
                  <div class="">
                    <input type="text" name="txtClientTitle" id="txtClientTitle_{{t}}" placeholder="Title"
                      class="form-control rounded-0 h-30 edp-font-12 w-97" [(ngModel)]="clientTeamMember.title"
                      maxlength="50" (blur)="checkSpaceChartBegin($event)">
                  </div>
                </td>
                <td class="td-sep2">
                  <div class="h-28">
                    <div class="selectdiv selectdiv-drop w-100">
                      <select id="drpClientTeamMemberPermissions_{{t}}" name="ClientTeamMemberPermission_{{t}}"
                        class="form-control dropdown rounded-0 edp-font-12 h-40"
                        [(ngModel)]="clientTeamMember.permissionId">
                        <option value="null" disabled hidden>Select permissions</option>
                        <option id="drpTeamMemberPermissionsOptions_{{t}}"
                          *ngFor="let permission of clientMemberPermissionModelList" [value]=permission.id>
                          {{permission.typeValue}}
                        </option>
                      </select>
                    </div>
                    <div>
                      <span id="errorLabelClientTeamMemberPermission{{t}}" style="display:none;"
                        class="label error-color edp-font-12">
                        Select permission</span>
                    </div>
                  </div>
                </td>
                <td class="txt-last">
                  <div class="">
                    <a id="removeLink_{{t}}" (click)="removeClientNewTeamMember(t)">
                      <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                        class="middle-align">
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end each client team teamdetails_-->

      </div>
      <div class="modal-footer text-right">

        <!-- start divNewClientTeamMember -->
        <div id="divAddClientTeamMember_0"
          class="row mx-0 mb-3 py-2 bg-edp-body align-items-center addTeamMemberbuttons">
          <div class="col-xl-9 col-md-8">
            <span>
              <a class="link-text-blue d-flex align-items-center" id="btnAddClientTeamMember_0"
                (click)="addClientTeamMember()">
                <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                <span class="d-inline-block pl-2"> Add a new client user </span>
              </a>
            </span>
          </div>
        </div>
        <!-- End divNewClientTeamMember -->

        <!-- start OK and Cancel Buttons Div-->
        <div class="row m-0 text-right mb-2" id="divOKCancelSection_0">
          <div class="col-md-12 text-right pr-0">
            <div id="success-validation-section mb-1" class="text-right">
              <span id="member-save-success" class="text-right pt-1 d-none error-color">Client(s) user
                added successfully</span>
            </div>
            <button type="button" class="SecondaryButton mr-2" data-dismiss="modal" aria-hidden="true"
              (click)="resetClientTeamMemberPopup()" style="width: 150px; text-align: center; ">
              <span>
                <img class="d-inline-block mr-1 align-top" src="../../../assets/images/EDP/Engagement/cancel-24px.svg"
                  alt="Cancel" />
              </span>
              <span id="manageTeam-close-cancel-cap" class="d-inline-block align-middle"> Cancel</span>
            </button>
            <button class="PrimaryButton" type="button" style="width: 150px; text-align: center; "
              (click)="SaveClientTeamMember()">
              <span>
                <img class="d-inline-block mr-1  align-top" src="../../../assets/images/EDP/Engagement/ok-24px.svg"
                  alt="ok" />
              </span>
              <span class="d-inline-block align-middle"> Add </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- end Client Team Members Modal -->
<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>

<!--Start Sharepoint File Label POPUP-->
<app-sharepoint-file-label [sharePointSelectedFileLabel]="sharePointSelectedFileLabel"
  (close)="cancelSharepointFileLabel()" (ok)="okSharepointFileLabel($event)"></app-sharepoint-file-label>
<!--End Sharepoint File Label POPUP-->

<!--Start Audit Partner POPUP-->
<app-audit-partner-dialog *ngIf="showAuditPopup" #auditPartnerDialog [dialogDescriptionType]="dialogDescriptionType"
  [workspaceId]="workspaceId" [loggedInUserEmail]="userInfo.loggedInUserEmail" (close)="cancelAuditPartnerClicked()"
  (ok)="okAuditPartnerClicked($event)"></app-audit-partner-dialog>
<!--End Audit Partner POPUP-->